<template>
  <div>
    <v-select
      :prepend-icon="icon ? icons.mdiAlphaCCircle : null"
      :clearable="clearable"
      hide-details="auto"
      :disabled="disabled"
      v-model="select"
      :items="lista"
      :label="label"
      item-value="id"
      item-text="nombre"
      :dense="outlined"
      :outlined="outlined"
      :loading="cargando"
      :append-outer-icon="add ? icons.mdiPlusCircleOutline : null"
      @click:append-outer="abrirModal()"
      :rules="rules"
      multiple
    >
      <template v-slot:selection="{ item, index }">
        <v-chip v-if="index === 0">
          <span>{{ item.nombre }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption"> (+{{ value.length - 1 }} others) </span>
      </template>
    </v-select>
  </div>
</template>
<script>
import { onBeforeMount, ref, watch } from '@vue/composition-api'
import { mdiAlphaCCircle, mdiPlusCircleOutline } from '@mdi/js'
import ProductosService from '@/api/servicios/ProductosService'
import store from '@/store'
export default {
  components: {},
  props: {
    value: String,
    jsonRespueta: Boolean,
    disabled: Boolean,
    dense: Boolean,
    outlined: Boolean,
    icon: Boolean,
    todos: Boolean,
    default: Number,
    clearable: Boolean,
    padre: {
      type: Number,
      default: -1,
    },
    rules: Array,
    add: {
      type: Boolean,
      default: true,
    },
    ind_principal: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Locatario/Area',
    },
    storePuesto: {
      type: Boolean,
      default: false,
    },
    modulos: {
      type: Array,
      default: null,
    },
    filtros: Object,
  },
  watch: {
    filtros: function (_new, _old) {
      if ( JSON.stringify(_new)  != JSON.stringify(_old)) {
      
        this.cargarDatos()
      }
    },
  },
  setup(props, contex) {
    const lista = ref([])
    const select = ref(null)
    const cargando = ref(false)
    const dialog = ref(false)

    watch(props, () => {
      select.value = props.value
    })
    onBeforeMount(() => {
      cargarDatos()
      if (props.default != undefined) {
        select.value = props.default
      }
    })

    const GetActualizarLista = item => {
      lista.value.push(item)
      select.value = item.id
      dialog.value = false
    }
    const abrirModal = () => {
      dialog.value = true
    }
    const cargarDatos = () => {
      lista.value = []

      if (props.storePuesto) {
        const puestos = []
        store.state.user.arbol.forEach(element => {
          if (element.id_puesto > -1) {
            puestos.push({ id: element.id_puesto, nombre: element.nombre })
          }
        })

        lista.value = puestos
      } else {
        // try {

        cargando.value = true
        ProductosService.obtenerPuestos({
          ind_principal: props.ind_principal,
          modulos: props.modulos?.join(','),
          id_usuario: store.state.user.id,
          ...props.filtros,
        })
          .then(response => {
            console.log(response)
            if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
              if (props.todos == true) {
                lista.value.push({ id: -1, nombre: 'TODOS' })
              }
              lista.value = response.data.datos
            }
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            cargando.value = false
          })
        //} catch (error) {
        //  alert()
        //   cargando.value = false
        //}
      }
    }

    watch(select, () => {
      const valor = lista.value.find(item => item.id == select.value)

      if (props.jsonRespueta == true) {
        contex.emit('input', valor == undefined ? null : valor)
      } else contex.emit('input', select.value)
    })

    return {
      icons: {
        mdiAlphaCCircle,
        mdiPlusCircleOutline,
      },
      lista,
      select,
      cargando,
      dialog,
      abrirModal,
      GetActualizarLista,
      cargarDatos,
      store,
    }
  },
}
</script>