//import { axios } from '@/api';
import Axios from 'axios';
import store from '@/store'
import http from "../../..";
import router from '@/router'


class AlmacenServices {
  
  listarAlmacen(params) {  
    return http.get("/inventario/almacen/listarAlamacen", { params })
    .catch((error) => { 
       if (error.response.status == 403) {
        store.commit("setUserData", { id: 0, userName: "", IsAutenticated: false });
        router.push('/pages/login').catch(error => {
        if (error.name != 'NavigationDuplicated') {
          throw error
        }
      });
      }else{
        throw error
      } 
    });
  }  
   
}

export default new AlmacenServices();